import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import "./bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Portfolio from "./pages/portfolio";
import Calandar from "./pages/calandar";
import Overview from "./pages/overview";
import Screener from "./pages/screener";
import Error from "./pages/error";
import Home from "./pages/home";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import axiosThrottle from "axios-request-throttle";
import ProtectedRoutes from "./ProtectedRoutes";
import Navbar from "./components/navbar";
import "./App.css";
import SettingsPage from "./pages/settings";
import Target from "./pages/target";
import { DataProvider } from "./DataContext";
import DataContext from "./DataContext";

axiosThrottle.use(axios, { requestsPerSecond: 5 });

function App() {
  const { setData } = useContext(DataContext);

  const [portData, setPortData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [value, setValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [version, setVersion] = useState("free");
  const [div, setDiv] = useState(0);
  const [userData, setUserData] = useState({
    data: {
      currency: "GBP",
      divGoal: 10,
      diversification: [],
      wishlist: [],
      alerts: [],
    },
  });

  const navigate = useNavigate();

  function calculateTotalPrice(data) {
    let totalPrice = 0;

    for (let i = 0; i < data.length; i++) {
      const innerArray = data[i];

      // Calculate total shares bought
      const totalSharesBought = innerArray.buyData.reduce(
        (total, item) => total + (item.noOfSharesBought || 0),
        0
      );

      // Calculate total shares sold
      const totalSharesSold = Array.isArray(innerArray.soldData)
        ? innerArray.soldData.reduce(
            (total, item) => total + (item.noOfSharesSold || 0),
            0
          )
        : 0;

      // Remaining shares
      const remainingShares = totalSharesBought - totalSharesSold;

      // Calculate value of remaining shares
      const arrayTotal = remainingShares * innerArray.price;

      // Add to total price
      totalPrice += arrayTotal;
    }

    // Return the total value
    return totalPrice;
  }

  function getAnnualDividendValue(arr) {
    let total = 0;
    for (let i = 0; i < arr.length; i++) {
      const dividends = arr[i].divDate;
      for (let j = 0; j < dividends.length; j++) {
        isWithinLastYear(new Date(dividends[j].date)) &&
          (total += dividends[j].dividend);
      }
    }
    return total;
  }
  function isWithinLastYear(inputDate) {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date from exactly one year ago
    const lastYearDate = new Date();
    lastYearDate.setFullYear(currentDate.getFullYear() - 1);

    // Compare the input date with the date from one year ago
    return inputDate >= lastYearDate && inputDate <= currentDate;
  }
  async function getSharesOnDate(unixDate) {
    const targetDate = new Date(unixDate * 1000); // Convert Unix timestamp to JavaScript Date object.

    const totalBought = portData.buyData
      .filter((entry) => new Date(entry.date) <= targetDate)
      .reduce((acc, entry) => acc + entry.noOfSharesBought, 0);

    const totalSold =
      portData.soldData.length > 0
        ? portData.soldData
            .filter((entry) => new Date(entry.date) <= targetDate)
            .reduce((acc, entry) => acc + entry.noOfSharesSold, 0)
        : 0;

    const shares = totalBought - totalSold;
    console.log(shares);
    return shares;
  }

  async function isNewDay(data) {
    // Retrieve the last loaded date from localStorage
    var lastLoadedDate = localStorage.getItem("lastLoadedDate");

    // Get the current date
    var currentDate = new Date().toLocaleDateString();

    // If lastLoadedDate is not set or it's different from the current date, it's a new day
    if (lastLoadedDate != currentDate) {
      // Store the current date in localStorage for future reference
      localStorage.setItem("lastLoadedDate", currentDate);
      //Update DIVIDENDS
      //do check to see if any new dividends have been given out since the users data shows for each stock. If so add it to divDate and update mongodb
      console.log(data);

      let result = [];

      for (const item of data) {
        if (!item.divDates || !item.previousDiv) continue;

        let minDivDate = Math.min(
          ...item.divDates.map((entry) => new Date(entry.date).getTime())
        );

        for (const prevEntry of item.previousDiv) {
          let prevDateTimestamp = new Date(prevEntry.date).getTime();

          if (prevDateTimestamp > minDivDate) {
            let unixDate = Math.floor(prevDateTimestamp / 1000);
            let shares = await getSharesOnDate(unixDate);
            let totalAmount = shares * prevEntry.amount;

            result.push({
              symbol: item.symbol,
              date: prevEntry.date,
              totalAmount: totalAmount,
              automated: true,
            });
          }
        }
      }

      result > 0
        ? axios
            .post(process.env.REACT_APP_API_UPDATE_DIVIDENDS, {
              params: {
                user: JSON.parse(localStorage.getItem("data")).email,
                token: JSON.parse(localStorage.getItem("data")).token,
                data: result,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${
                  JSON.parse(localStorage.getItem("data")).token
                }`,
              },
            })
            .then((res) => {
              console.log(res);
            })
            .catch(() => {
              console.log("ERROR");
            })
        : console.log(result);
      return result;
    }
  }

  useEffect(() => {
    setIsLoading(true);
    document.body.style.backgroundColor = "rgba(245,238,211,255)";

    const data =
      localStorage.getItem("data") && JSON.parse(localStorage.getItem("data"));
    console.log(data);

    if (data) {
      axios
        .get(process.env.REACT_APP_API_GET, {
          params: {
            user: data.email,
            token: data.token,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.token}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          isNewDay(res.data[0]);
          setPortData(res.data[0]);
          setValue(calculateTotalPrice(res.data[0]));
          setVersion(res.data[1].version);
          setDiv(getAnnualDividendValue(res.data[0]));
        })
        .catch(() => {
          navigate("/home", { replace: true });
          setData({ email: null, token: null });
        });

      axios
        .post(
          process.env.REACT_APP_API_GETCONFIG,
          { username: data.email },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${data.token}`,
            },
          }
        )
        .then((res) => {
          setUserData(res);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Not Logged in yet");
    }

    return;
  }, [update]);

  const location = useLocation();

  const getBackgroundClass = () => {
    switch (location.pathname) {
      case "/home":
        return "home-background";
      case "/portfolio":
        return "port-background";
      case "/calendar":
        return "cal-background";
      case "/overview":
        return "port-background";
      case "/targets":
        return "port-background";
      default:
        return "home-background";
    }
  };

  return (
    <>
      <div className={`App ${getBackgroundClass()}`} style={{ minWidth: 1100 }}>
        <Navbar />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route
            path="portfolio"
            element={
              <ProtectedRoutes>
                <Portfolio
                  data={portData}
                  setUpdate={setUpdate}
                  update={update}
                  value={value}
                  isLoading={isLoading}
                  version={version}
                  div={div}
                  userData={userData}
                />
              </ProtectedRoutes>
            }
          />
          <Route
            path="calendar"
            element={
              <ProtectedRoutes>
                <Calandar data={portData} update={update} userData={userData} />
              </ProtectedRoutes>
            }
          />
          <Route
            path="overview"
            element={
              <ProtectedRoutes>
                <Overview data={portData} value={value} userData={userData} />
              </ProtectedRoutes>
            }
          />
          <Route
            path="settings"
            element={
              <ProtectedRoutes>
                <SettingsPage userData={userData} />
              </ProtectedRoutes>
            }
          />
          <Route
            path="targets"
            element={
              <ProtectedRoutes>
                <Target data={portData} div={div} userData={userData} />
              </ProtectedRoutes>
            }
          />
          <Route path="screener" element={<Screener />} />
          <Route path="error" element={<Error />} />

          <Route
            path="*"
            element={
              <div style={{ paddingTop: 100 }}>
                <h1>PAGE NOT FOUND!</h1>
              </div>
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
