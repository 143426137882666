import React, { useEffect, useState } from "react";
import ProgressBar from "./progress";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
} from "recharts";
import colourCodes from "../colours";

const AmountInput = (props) => {
  const [divAmount, setDivAmount] = useState("");
  const [savedDivAmount, setDivSavedAmount] = useState("");

  useEffect(() => {
    setDivSavedAmount(props.configData.divGoal);
  }, [props.configData.divGoal]);

  const handleDivInputChange = (event) => {
    setDivAmount(event.target.value);
  };

  const handleDivInputBlur = () => {
    setDivSavedAmount(divAmount);
    props.updateConfig("divGoal", divAmount);
    setDivAmount("");
  };

  const formatTooltip = (value, name) => {
    const formattedValue = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: props.configData.currency ? props.configData.currency : "GBP",
    }).format(value);

    // Customize the key labels here
    const customName =
      name === "totalStockValue"
        ? "Total Stock Value"
        : name === "total"
        ? "Total Cost"
        : name;

    return [formattedValue, customName];
  };

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <h1
        style={{
          fontFamily: "Helvetica Neue, Arial, sans-serif",
          fontSize: "1.5em",
          fontWeight: 700,
          color: "#333",
          margin: "0.5em 0",
          lineHeight: 1.2,
          letterSpacing: "0.05em",
          textAlign: "center",
          textTransform: "uppercase",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          borderBottom: "2px solid #e2e2e2",
          paddingBottom: "0.3em",
        }}
      >
        Dividend Goal
      </h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ transform: "translateY(10px)" }}>
          <p>
            Monthly Dividend Goal:
            {savedDivAmount
              ? new Intl.NumberFormat("en-UK", {
                  style: "currency",
                  currency: props.configData.currency,
                }).format(savedDivAmount)
              : ""}
          </p>
        </div>
        <input
          type="number"
          id="amountDivInput"
          value={divAmount}
          onChange={handleDivInputChange}
          onBlur={handleDivInputBlur}
          style={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            margin: "10px",
          }}
        />
      </div>
      <ProgressBar
        currency={props.configData.currency ? props.configData.currency : "GBP"}
        current={Number(parseFloat((props.div / 12).toFixed(2)))}
        total={savedDivAmount}
      />
      <>
        <ResponsiveContainer height={600} width="100%">
          <BarChart
            height={600}
            data={props.barData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip formatter={formatTooltip} />
            {props.data.map((n, index) => (
              <Bar
                dataKey={n.symbol}
                stackId="a"
                fill={colourCodes[index]}
                shape={<CustomBar />}
              />
            ))}
            <Line
              type="monotone"
              dataKey="Total"
              stroke="#E8DBCD"
              fill="#E8DBCD"
              strokeWidth={4}
            />
          </BarChart>
        </ResponsiveContainer>
      </>
    </div>
  );
};

export default AmountInput;
const CustomBar = (props) => {
  const { x, y, width, height, fill } = props;
  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      stroke="#333" // Border color
      strokeWidth="2" // Border thickness
      rx="4" // Optional: rounded corners
      ry="4"
      style={{
        filter: "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4))", // Shadow effect
      }}
    />
  );
};
