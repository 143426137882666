import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import "../bootstrap.min.css";
import { Spinner } from "react-bootstrap";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import colourCodes from "../colours";
import DataContext from "../DataContext";

function Calandar(props) {
  const [events, setEvents] = useState([]);
  const [estDiv, setEstDiv] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useContext(DataContext);

  function sumTotals(data) {
    let totalSum = 0;

    data.forEach((item) => {
      totalSum += parseFloat(item.Total);
    });

    return totalSum;
  }
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_CALANDAR, {
        params: {
          user: data.email,
          token: data.token,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setEvents(res.data[0]);
        setEstDiv(res.data[1]);
      });
  }, [props.update]);

  return (
    <div>
      {isLoading ? (
        <Spinner></Spinner>
      ) : (
        <div style={{ display: "flex", margin: 30 }}>
          <div
            style={{
              flex: 3,
              backgroundColor: " rgba(78, 122, 140, 0.9)",
              padding: 30,
              borderRadius: 15,
              boxShadow: "10px 10px 10px 10px",
              margin: 20,
              position: "relative", // Added to allow absolute positioning inside
              overflow: "visible", // Ensure content can spill outside
            }}
          >
            <FullCalendar
              plugins={[dayGridPlugin, bootstrap5Plugin]}
              initialView="dayGridMonth"
              eventContent={(eventInfo) => {
                return (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "5px",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) => {
                        const tooltip = e.currentTarget.nextSibling;
                        tooltip.style.visibility = "visible";
                        tooltip.style.opacity = "1";
                      }}
                      onMouseLeave={(e) => {
                        const tooltip = e.currentTarget.nextSibling;
                        tooltip.style.visibility = "hidden";
                        tooltip.style.opacity = "0";
                      }}
                    >
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                          backgroundColor: eventInfo.event.backgroundColor,
                          marginRight: "5px",
                          flexShrink: 0,
                        }}
                      />
                      <div
                        style={{
                          whiteSpace: "normal",
                          overflow: "visible",
                          textOverflow: "clip",
                        }}
                      >
                        {eventInfo.event.title}
                      </div>
                    </div>

                    <div
                      style={{
                        visibility: "hidden",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        color: "#fff",
                        textAlign: "left",
                        padding: "8px",
                        borderRadius: "6px",
                        position: "absolute", // Ensure tooltip stays within the viewport
                        zIndex: 9999,
                        bottom: "auto",
                        left: "auto",
                        transform: "translate(10px, 10px)",
                        opacity: 0,
                        transition: "opacity 0.2s ease",
                        whiteSpace: "normal",
                        maxWidth: "200px",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.3)",
                        fontSize: "12px",
                        lineHeight: "1.4",
                        pointerEvents: "none",
                      }}
                    >
                      {eventInfo.event.title.includes("News")
                        ? eventInfo.event.extendedProps.newsTitle ||
                          eventInfo.event.newsTitle
                        : (() => {
                            const month = eventInfo.event.start.toLocaleString(
                              "default",
                              { month: "short" }
                            );
                            const year = eventInfo.event.start.getFullYear();
                            const key = `${year} ${month}`;
                            const stockTitle = eventInfo.event.title;

                            let matchingEntry = estDiv.find(
                              (entry) => entry.name === key
                            );
                            let index = estDiv.findIndex(
                              (entry) => entry.name === key
                            );

                            while (
                              matchingEntry &&
                              !Object.keys(matchingEntry).some((symbol) =>
                                stockTitle.includes(symbol)
                              ) &&
                              index < estDiv.length - 1
                            ) {
                              index++;
                              matchingEntry = estDiv[index];
                            }

                            const matchedSymbol = matchingEntry
                              ? Object.keys(matchingEntry).find((symbol) =>
                                  stockTitle.includes(symbol)
                                )
                              : null;

                            return matchedSymbol
                              ? new Intl.NumberFormat("en-UK", {
                                  style: "currency",
                                  currency: props.userData.data.currency,
                                }).format(matchingEntry[matchedSymbol])
                              : null;
                          })()}
                    </div>
                  </div>
                );
              }}
              events={events}
              contentHeight={600}
              expandRows={true}
              themeSystem="bootstrap5"
              eventClick={(info) => {
                info.jsEvent.preventDefault();
                if (info.event.url) window.open(info.event.url);
              }}
            />
          </div>

          <div
            style={{
              flex: 1,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              padding: 30,
              borderRadius: 15,
              boxShadow: "10px 10px 10px 10px",
              margin: 20,
            }}
          >
            <h5>Estimated Dividends</h5>
            <ResponsiveContainer width="100%" height={600}>
              <BarChart
                layout="vertical"
                data={estDiv}
                margin={{
                  top: 20,
                  right: 30,
                  left: 10,
                  bottom: 5,
                }}
              >
                <XAxis type="number" />
                <YAxis
                  dataKey="name"
                  type="category"
                  tickFormatter={(tick) =>
                    tick.length > 4 ? tick.substring(4) : tick
                  }
                />
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div
                          style={{
                            backgroundColor: "#fff",
                            padding: "10px",
                            border: "1px solid #ccc",
                          }}
                        >
                          {payload.map((entry, index) => (
                            <p key={`item-${index}`}>{`${
                              entry.name
                            }: £${entry.value.toFixed(2)}`}</p>
                          ))}
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                {props.data.map((n, index) => (
                  <Bar
                    key={n.symbol}
                    dataKey={n.symbol}
                    stackId="a"
                    fill={colourCodes[index]}
                    shape={<CustomBar />} // Apply custom shape
                  >
                    {index === props.data.length - 1 && (
                      <LabelList dataKey="Total" position="right" />
                    )}
                  </Bar>
                ))}
              </BarChart>
            </ResponsiveContainer>
            Total:{" "}
            {new Intl.NumberFormat("en-UK", {
              style: "currency",
              currency: props.userData.data.currency,
            }).format(sumTotals(estDiv))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Calandar;

const CustomBar = (props) => {
  const { x, y, width, height, fill } = props;
  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      stroke="#333" // Border color
      strokeWidth="2" // Border thickness
      rx="4" // Optional: rounded corners
      ry="4"
      style={{
        filter: "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4))", // Shadow effect
      }}
    />
  );
};
