import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const OptionsPercentageInput = (props) => {
  const [selectedOption, setSelectedOption] = useState("Consumer Goods");
  const [percentage, setPercentage] = useState(0);
  const [optionsList, setOptionsList] = useState([]);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const sectors = [
    "Consumer Goods",
    "Financials",
    "Health Care",
    "Industrials",
    "Basic Materials",
    "Oil & Gas",
    "Technology",
    "Telecommunications",
    "Utilities",
    "Consumer Services",
    "Real Estate",
  ];

  const COLORS = [
    "#E8DBCD", // orange red
    "#D5DbCE", // gold
    "#8F8861", // sea green
    "#F0EFE6", // dark sea green
    "#EAEAEA", // crimson
    "#C6C9D2", // dark turquoise
    "#DCCFC1", // dark magenta
    "#B8AEA8", // hot pink
    "#D0EEF2", // coral
    "#FCE6D0", // spring green
    "#98A7B8", // turquoise
    "#7B9080", // medium slate blue
    "#3E4B4A", // dark violet
    "#CCB363", // forest green
    "#C27E35", // tomato
    "#6F7D7B", // olive drab
    "#76290B", // dark orchid
    "#1E3226", // firebrick
    "#008080", // teal
    "#4B0082", // indigo
    "#00FA9A", // medium spring green
    "#FFC0CB", // pink
    "#0000CD", // medium blue
    "#FFA07A", // light salmon
    "#66CDAA", // medium aquamarine
    "#9370DB", // medium purple
    "#F08080", // light coral
    "#1E90FF", // dodger blue
    "#FF8C00", // dark orange
    "#48D1CC", // medium turquoise
    "#FF1493", // deep pink
    "#008000", // green
    "#00BFFF", // deep sky blue
    "#DDA0DD", // plum
    "#20B2AA", // light sea green
    "#ADFF2F", // green yellow
  ];

  useEffect(() => {
    setOptionsList(props.configData.diversification);
  }, [props.configData.diversification]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePercentageChange = (event) => {
    setPercentage(event.target.value);
  };

  const handleAddClick = async () => {
    if (percentage === "" || selectedOption === "") {
      setErrorMessage("Please select an option and input a percentage.");
      return;
    }

    const newPercentage = parseInt(percentage);
    const existingOptionIndex = optionsList.findIndex(
      (option) => option.option === selectedOption
    );

    let newTotalPercentage = totalPercentage;
    if (existingOptionIndex !== -1) {
      newTotalPercentage -= optionsList[existingOptionIndex].percentage;
    }

    newTotalPercentage += newPercentage;

    if (newTotalPercentage > 100) {
      setErrorMessage("Total percentage cannot exceed 100%.");
      return;
    }

    if (existingOptionIndex !== -1) {
      const newOptionsList = [...optionsList];
      newOptionsList[existingOptionIndex].percentage = newPercentage;
      setOptionsList(newOptionsList);
    } else {
      const newOptionsList = [
        ...optionsList,
        { option: selectedOption, percentage: newPercentage },
      ];
      setOptionsList(newOptionsList);
      props.updateConfig("diversification", newOptionsList);
    }

    setTotalPercentage(newTotalPercentage);
    setPercentage("");
    setSelectedOption("");
    setErrorMessage("");
  };

  return (
    <div style={{ fontFamily: "Arial, sans-serif" }}>
      <h1
        style={{
          fontFamily: "Helvetica Neue, Arial, sans-serif",
          fontSize: "1.5em",
          fontWeight: 700,
          color: "#333",
          margin: "0.5em 0",
          lineHeight: 1.2,
          letterSpacing: "0.05em",
          textAlign: "center",
          textTransform: "uppercase",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          borderBottom: "2px solid #e2e2e2",
          paddingBottom: "0.3em",
        }}
      >
        Diversification Goal
      </h1>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ marginRight: "20px" }}>
          <label htmlFor="options">Select a Sector:</label>
          <select
            id="options"
            value={selectedOption}
            onChange={handleOptionChange}
            style={{ padding: "5px", display: "block" }}
          >
            {sectors.map((sector, index) => (
              <option key={index} value={sector}>
                {sector}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="percentage">Percentage:</label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="number"
              id="percentage"
              value={percentage}
              onChange={handlePercentageChange}
              min="0"
              max={100 - totalPercentage}
              style={{ padding: "5px", marginRight: "10px", width: "60px" }}
            />
            <span>%</span>
          </div>
        </div>
        <Button onClick={handleAddClick}>Add</Button>
      </div>
      {errorMessage && (
        <p style={{ color: "red", margin: "5px 0" }}>{errorMessage}</p>
      )}
      <div style={{ padding: 10, display: "flex", justifyContent: "center" }}>
        <PieChart width={500} height={500}>
          <Pie
            data={optionsList}
            dataKey="percentage"
            nameKey="option"
            cx="50%"
            cy="50%"
            outerRadius={180}
            fill="#8884d8"
            label
            stroke="black"
            strokeWidth={2}
          >
            {optionsList.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>
    </div>
  );
};

export default OptionsPercentageInput;
