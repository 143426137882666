import React from "react";
import "./progress.css";

const ProgressBar = ({ current, total, currency }) => {
  const percentage = Math.min((current / total) * 100, 100);

  let backgroundColor;
  if (current > total) {
    backgroundColor = "#E8DBCD";
  } else if (percentage > 80) {
    backgroundColor = "#D5DbCE";
  } else if (percentage > 50) {
    backgroundColor = "#8F8861";
  } else if (percentage > 20) {
    backgroundColor = "#F0EFE6";
  } else if (percentage > 10) {
    backgroundColor = "#EAEAEA";
  }

  return (
    <div>
      <div className="progress-bar">
        <div
          className="progress-bar__fill"
          style={{ width: `${percentage}%`, backgroundColor }}
        ></div>
        <span className="progress-bar__text">
          {new Intl.NumberFormat("en-UK", {
            style: "currency",
            currency: currency,
          }).format(current)}
        </span>
      </div>
      {percentage >= 100 ? <text>Goal reached! Aim higher</text> : null}
    </div>
  );
};

export default ProgressBar;
